import { Link } from "react-router-dom";
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const PortfolioPage = () => {
    return (
        <>
            <body className="sub_page">
                <div className="hero_area">
                    {/* <!-- header section strats --> */}
                    <header className="header_section">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                                <Link to='/' className="navbar-brand">
                                    <span>
                                        <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                    </span>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                        <ul className="navbar-nav  ">
                                            <li className="nav-item">
                                                <Link to='/' className="nav-link"> Home </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/about' className="nav-link"> About Us</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/services' className="nav-link"> Services </Link>
                                            </li>
                                            <li className="nav-item active-menu">
                                                <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                            </li>
                                            <li className="nav-item">
                                            <Link to='/gallery' className="nav-link"> Gallery </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/contact' className="nav-link">Contact us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- end header section --> */}
                </div>


                {/* <!-- work section --> */}
                <section className="work_section layout_padding">
                    <div className="container">
                        <div className="heading_container">
                            <h2>
                                Film Production Works
                            </h2>
                            <p>
                                We have created multiple creative work in the field of <span className="text-bold">FILM MAKING</span>, which were nominated and have won bunch of awards.
                            </p>
                        </div>
                        <div className="work_container layout_padding2">
                            <div className="box b-1 col-3" style={{ border: "3px solid black" }}>
                                <img src="images/the_burnt_soil.png" alt="the burnt soil documentary poster" />
                            </div>
                            <div className="box b-2 col-3" style={{ border: "3px solid black" }}>
                                <img src="images/Vanish Poster_updated_with Laurels4.jpg" alt="vanish short film poster" />
                            </div>
                            <div className="box b-3 col-3" style={{ border: "3px solid black" }}>
                                <img src="images\rittij copy.jpg" alt="boomerang short film poster" />
                            </div>
                            <div className="box b-4 col-3 my-5" style={{ border: "3px solid black" }}>
                                <img src="images\Poster_2B copy.png" alt="stalker short film poster" />
                            </div>
                        </div>

                        <div className="heading_container">
                            <h2>
                                Corporate Marketing Contents
                            </h2>
                            <p>
                                We have created multiple <span className="text-bold">marketing contents</span> for other companies, which helped them to market their products
                            </p>
                        </div>
                        <div className="work_container layout_padding2">
                            <div className="box b-1">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/uBKVNNRm524?si=k4Lo6xiZOOqQz4Gw" style={{ border: "3px solid black" }} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="box b-2">

                            </div>
                            <div className="box b-3 mx-4">

                            </div>
                            <div className="box b-4 my-3">

                            </div>
                        </div>
                    </div>
                </section>
            </body>

            {/* <!-- end work section --> */}
        </>
    )
}

export default PortfolioPage;