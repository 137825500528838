import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Layout = () => {
    return (
        <>
        <div>
            <section>
                <Outlet></Outlet>
            </section>
            <Footer></Footer>
        </div>
        </>
    )
}

export default Layout;