import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- info section --> */}
      <section className="info_section ">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="info_contact">
                <h5>
                  Contact Info
                </h5>
                <div>
                  <div className="img-box">
                    <img src="images/location-white.png" width="18px" alt="address - Veera Desai Road, Andheri West, Mumbai 400053" />
                  </div>
                  <p>
                    Veera Desai Road,<br></br> Andheri West, Mumbai 400053
                  </p>
                </div>
                {/* <div>
                  <div className="img-box">
                    <img src="images/telephone-white.png" width="12px" alt="" />
                  </div>
                  <p>
                    +01 1234567890
                  </p>
                </div> */}
                <div>
                  <div className="img-box">
                    <img src="images/envelope-white.png" width="16px" alt="email - bigcreativeideasentertainments@gmail.com" />
                  </div>
                  <p>
                    bigcreativeideasentertainments@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="info_info">
                <h5>
                  Informations
                </h5>
                <div>
                  <Link to='/about' className="footer-info">About Us</Link>
                </div>
                <div className="my-3">
                  <Link to='/services' className="footer-info">Services</Link>
                </div>
                <div>
                  <Link to='/contact' className="footer-info">Contact Us</Link>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="info_form ">
                <div className="social_box">
                  <a href="https://www.facebook.com/bigcreativeideasentertainment/" target="_blank">
                    <img src="images/fb.png" alt="open facebook page" />
                  </a>
                  <a href="https://www.youtube.com/@bigcreativeideas" target="_blank">
                    <img src="images/youtube.png" alt="open youtube page" />
                  </a>
                  <a href="https://www.instagram.com/bigcreativeideasentertainment/" target="_blank">
                    <img src="images/services/icons8-instagram-30.png" alt="open instagram page" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      {/* <!-- end info_section --> */}
      {/* <!-- footer section --> */}
      <section className="container-fluid footer_section">
        <p>
          &copy; 2023 All Rights Reserved By
          Big Creative Ideas Entertainment
        </p>
      </section>
      {/* <!-- footer section --> */}
    </>
  )
}

export default Footer;