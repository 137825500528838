import { Link } from "react-router-dom";
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const AboutPage = () => {
    return (
        <>
            <body className="sub_page">
                <div className="hero_area">
                    {/* <!-- header section strats --> */}
                    <header className="header_section">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                                <Link to='/' className="navbar-brand">
                                    <span>
                                        <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                    </span>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                        <ul className="navbar-nav  ">
                                            <li className="nav-item">
                                                <Link to='/' className="nav-link"> Home</Link>
                                            </li>
                                            <li className="nav-item active-menu">
                                                <Link to='/about' className="nav-link"> About Us</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/services' className="nav-link"> Services </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/gallery' className="nav-link"> Gallery </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/contact' className="nav-link">Contact us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- end header section --> */}
                </div>


                {/* <!-- who section --> */}

                <section className="who_section layout_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="img-box" style={{ border: "3px solid yellow" }}>
                                    <img src="BCIE_logo-3-1.png" alt="company logo"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            WHO WE ARE?
                                        </h2>
                                    </div>
                                    <p>
                                        Welcome to our community where technology meets creativity and bring your vision and imagination to life. We at the Big Creative Ideas entertainment company, aim to bring the best and deliver the inimitable result.
                                        For the growth and boost in your business, it is preeminent to find the best corporate video making company and you have landed at the right place at right time.
                                        Big creative ideas Entertainment company is a passionate and cost effective video production company that aims to build a mind boggling effect in the audience's mind.

                                    </p>
                                    <br></br>
                                    <div className="heading_container">
                                        <h2>
                                            WHY CHOOSE US?
                                        </h2>
                                    </div>
                                    <p>
                                        Choose Big Creative Idea entertainment, if you want to give a breath of fresh air to your narratives with storytelling in a unique and captivating manner, that transcend the boundaries of the industries.
                                        We take the ownership and full responsibilities of every project from the time of its inception to the delivery of the project.
                                        We are the best video production company having array of experiences from different industries, creating compelling ideas videos irrespective of the field.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- end who section --> */}
            </body>
        </>
    )
}

export default AboutPage;