import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Components/IndexHome';
import Layout from './Components/Common/Layout';
import AboutPage from './Components/About/AboutPage';
import PortfolioPage from './Components/Portfolio/PortfolioPage';
import ContactPage from './Components/Contact/ContactPage';
import ServicesPage from './Components/Services/Services';
import PageNotFound from './Components/PageNotFound';
import Gallery from './Components/Gallery/Gallery';

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Layout/>}>
        <Route index element={<HomePage/>}></Route>
        <Route path='about' element={<AboutPage/>}></Route>
        <Route path='services' element={<ServicesPage/>}></Route>
        <Route path='portfolio' element={<PortfolioPage/>}></Route>
        <Route path='gallery' element={<Gallery/>}></Route>
        <Route path='contact' element={<ContactPage/>}></Route>

        <Route path='*' element={<PageNotFound />}></Route>
      </Route>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
