import axios from "axios";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
const URL_LINK = process.env.REACT_APP_API_LINK;
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const ContactPage = () => {

    const form = useRef();
    const [loader, setLoader] = useState(false);
    const [fullName, setFullName] = useState("");
    const [phoneNum, setPhoneNum] = useState(null);
    const [emailId, setEmailId] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const contactForm = async (e) => {
        e.preventDefault();
        // Database API Call to store the user.
        // const contactData = { fullName: fullName, email_Id: emailId, phone_Number: phoneNum, message_details: message };
        // const response = await axios.post(`${URL_LINK}/guestUser/add`, contactData);
        // const data = response.data;
        setLoader(true);
        emailjs.sendForm('service_ln7c2pc', 'template_r3fwh2k', form.current, 'yw9ZIGWJZOO0eyCgL')
            .then((result) => {
                console.log(result.text);
                setLoader(false);
                setSuccessMessage("Message sent successfully...");
            }, (error) => {
                console.log(error.text);
            });

    }

    return (
        <>
            <body className="sub_page">
                <div className="hero_area">
                    {/* <!-- header section strats --> */}
                    <header className="header_section">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                                <Link to='/' className="navbar-brand">
                                    <span>
                                        <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                    </span>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                        <ul className="navbar-nav  ">
                                            <li className="nav-item">
                                                <Link to='/' className="nav-link"> Home</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/about' className="nav-link"> About Us</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/services' className="nav-link"> Services </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                            </li>
                                            <li className="nav-item">
                                            <Link to='/gallery' className="nav-link"> Gallery </Link>
                                            </li>
                                            <li className="nav-item active-menu">
                                                <Link to='/contact' className="nav-link">Contact us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- end header section --> */}
                </div>

                {/* <!-- contact section --> */}

                <section className="contact_section layout_padding">
                    <div className="container">

                        <div className="heading_container">
                            <h2>
                                Request A Call Back
                            </h2>
                        </div>
                        <div className="">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-9 mx-auto">
                                        <div className="contact-form">
                                            <form ref={form} onSubmit={contactForm}>
                                                <div>
                                                    <input onChange={(e) => setFullName(e.target.value)} type="text" placeholder="Full Name" name="guest_name" />
                                                </div>
                                                <div>
                                                    <input onChange={(e) => setPhoneNum(e.target.value)} type="text" placeholder="Phone Number" name="guest_phone" />
                                                </div>
                                                <div>
                                                    <input onChange={(e) => setEmailId(e.target.value)} type="email" placeholder="Email Address" name="guest_email" />
                                                </div>
                                                <div>
                                                    <input onChange={(e) => setMessage(e.target.value)} type="text" placeholder="Message" className="input_message" name="guest_message" />
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button type="submit" className="btn_on-hover">
                                                        Send
                                                    </button>
                                                </div>
                                                <div style={{ "display": loader ? "block" : "none" }}>
                                                    <div className="wrapper1">
                                                        <span className="circle circle-1"></span>
                                                        <span className="circle circle-2"></span>
                                                        <span className="circle circle-3"></span>
                                                        <span className="circle circle-4"></span>
                                                        <span className="circle circle-5"></span>
                                                        <span className="circle circle-6"></span>
                                                        <span className="circle circle-7"></span>
                                                        <span className="circle circle-8"></span>
                                                    </div>
                                                </div>
                                                {successMessage && <p className="text-center text-bold text-success mt-4">{successMessage}</p>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="map-responsive">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60308.653712042615!2d72.78420701937958!3d19.138741500899336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b618b6d891dd%3A0x91f8a857c731d132!2sAndheri%20West%2C%20Mumbai%2C%20Maharashtra%20400047!5e0!3m2!1sen!2sin!4v1703079763070!5m2!1sen!2sin" width="950" height="450" style={{ border: "5px solid yellow" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </section>
                {/* <!-- end contact section --> */}
            </body >
        </>
    )
}

export default ContactPage;