import { Link } from "react-router-dom";
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const HomePage = () => {
    return (
        <>
            <div className="hero_area">
                {/* <!-- header section strats --> */}
                <header className="header_section">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                            <Link to="/" className="navbar-brand">
                                <span>
                                    <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                </span>
                            </Link>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <div class="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                    <ul className="navbar-nav">
                                        <li className="nav-item  active-menu">
                                            <Link to='/' className="nav-link"> Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/about' className="nav-link"> About Us</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/services' className="nav-link"> Services </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/gallery' className="nav-link"> Gallery </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/contact' className="nav-link">Contact us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                {/* <!-- end header section --> */}
                {/* <!-- slider section --> */}
                <section className=" slider_section position-relative">
                    <div className="container">
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="row">
                                        <div className="col">
                                            <div className="detail-box">
                                                <div>
                                                    
                                                    <h1>
                                                        Big Creative Ideas Entertainment
                                                    </h1>
                                                    <p>
                                                        Your solution to different problems
                                                    </p>
                                                    <div>
                                                        <Link to='/contact'>Contact us</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="row">
                                        <div className="col">
                                            <div className="detail-box">
                                                <div>
                                                    
                                                    <h1>
                                                        Spreading Ideas Through Screens.
                                                    </h1>
                                                    <p>
                                                        Join us with our journey
                                                    </p>
                                                    <div>
                                                        <Link to='/contact'>Contact us</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* <!-- end slider section --> */}
            </div>
            <section className="do_section layout_padding">
                <div className="container">

                    <div className="heading_container">
                        <h2>
                            What we do
                        </h2>
                        <p>
                            We have solutions for a variety of problems
                        </p>
                    </div>

                    <div className="do_container">
                        <Link to='/services'>
                            <div className="box arrow-start arrow_bg">
                                <div className="img-box">
                                    <img src="images/d-1.png" alt="corporate video" />
                                </div>
                                <div className="detail-box">
                                    <h6 className="doc-text">
                                        Corporate Videos
                                    </h6>
                                </div>
                            </div>
                        </Link>
                        <Link to='/services'>
                            <div className="box arrow-middle arrow_bg">
                                <div className="img-box">
                                    <img src="images/d-2.png" alt="Industrial Videos" />
                                </div>
                                <div className="detail-box">
                                    <h6 className="doc-text">
                                        Industrial Videos

                                    </h6>
                                </div>
                            </div>
                        </Link>
                        <Link to='/services'>
                            <div className="box arrow-middle arrow_bg">
                                <div className="img-box">
                                    <img className="image-size" src="images\film.png" alt="Commercials" />
                                </div>
                                <div className="detail-box">
                                    <h6 className="doc-text">
                                        Commercials
                                    </h6>
                                </div>
                            </div>
                        </Link>
                        <Link to='/services'>
                            <div className="box arrow-end arrow_bg">
                                <div className="img-box">
                                    <img src="images/d-4.png" alt="Product Videos" />
                                </div>
                                <div className="detail-box">
                                    <h6 className="doc-text">
                                        Product Videos
                                    </h6>
                                </div>
                            </div>
                        </Link>
                        <Link to='/services'>
                            <div className="box ">
                                <div className="img-box">
                                    <img className="doc-box" src="images/services/png-transparent-documentary-film-removebg-preview.png" alt="Documentaries" />
                                </div>
                                <div className="detail-box">
                                    <h6 className="doc-text">
                                        Documentaries
                                    </h6>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

            {/* <!-- end do section --> */}

            {/* <section className="contact_section layout_padding">
                <div className="container">

                    <div className="heading_container">
                        <h2>
                            Request A Call Back
                        </h2>
                    </div>
                    <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-md-9 mx-auto">
                                    <div className="contact-form">
                                        <form action="">
                                            <div>
                                                <input type="text" placeholder="Full Name " />
                                            </div>
                                            <div>
                                                <input type="text" placeholder="Phone Number" />
                                            </div>
                                            <div>
                                                <input type="email" placeholder="Email Address" />
                                            </div>
                                            <div>
                                                <input type="text" placeholder="Message" className="input_message" />
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button type="submit" className="btn_on-hover">
                                                    Send
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section> */}
        </>
    )
}

export default HomePage;