import { Link } from "react-router-dom";
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const ServicesPage = () => {
    return (
        <>
            <body className="sub_page">
                <div className="hero_area">
                    {/* <!-- header section strats --> */}
                    <header className="header_section">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                                <Link to='/' className="navbar-brand">
                                    <span>
                                        <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                    </span>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                        <ul className="navbar-nav  ">
                                            <li className="nav-item">
                                                <Link to='/' className="nav-link"> Home</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/about' className="nav-link"> About Us</Link>
                                            </li>
                                            <li className="nav-item active-menu">
                                                <Link to='/services' className="nav-link"> Services </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                            </li>
                                            <li className="nav-item">
                                            <Link to='/gallery' className="nav-link"> Gallery </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/contact' className="nav-link">Contact us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- end header section --> */}
                </div>


                {/* <!-- who section --> */}

                <section className="who_section layout_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="img-box">
                                    <img style={{ border: "3px solid yellow" }} src="images/services/5.png" alt="Corporate Videos"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            Corporate Videos
                                        </h2>
                                    </div>
                                    <p>
                                    Corporate video implies to the making of the video that is specifically made for the development of the company and it is tailored in accordance with the features and requirement of the organization. 
                                    The corporate videos are made to serve various agenda of the company like marketing, internal communication and brand promotion.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-md-5">
                                <div className="img-box">
                                    <img style={{ border: "3px solid yellow" }} src="images/services/2.jpg"alt="Corporate Commercial"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            Corporate Commercial
                                        </h2>
                                    </div>
                                    <p>
                                    Corporate commercial is nothing but the commercial that are broadcasted in television.  We create engaging   and compelling corporate commercial, with storytelling, testimonials, demonstrations and various other techniques to convey the message and motive.  
                                    We aim to understand the tone, style and structure and target market of the client in order to deliver the best result. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-5">
                                <div className="img-box">
                                    <img style={{ border: "3px solid yellow" }} src="images/services/4.png" alt="Industrial Videos"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            Industrial Videos
                                        </h2>
                                    </div>
                                    <p>
                                    In order to create a compelling and structured industrial and manufacturing videos, it requires careful planning and execution. We have a professional and experienced team to create a high quality and theme generated industrial and manufacturing videos.<br></br>
                                    <b>Process we follow to create industrial and manufacturing videos:</b><br></br>
                                    First define objective and highlight the capabilities of the equipment.
                                    Effective communication by highlighting the specific features from start to finish.
                                    Scripting of the video should be crisp and precise with relevant  industrial setting.
                                    Paying attention to the details of the equipment, while filming the video, with close up shots and different angles. 
                                    Arranging the scenes according to the story board, by adding transition, graphic layout and background music, going parallel with the theme and concept. 
                                    Lastly, we even as a team review the content and gather feedback from the various sources, so that the content is full proof before going on floor. 

                                    </p>
                                </div>
                            </div>
                        </div>

                        <br></br>

                        <div className="row my-5">
                            <div className="col-md-5">
                                <div className="img-box">
                                    <img style={{ border: "3px solid yellow" }} src="images/services/3.jpg" alt="Product Videos"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            Product Videos
                                        </h2>
                                    </div>
                                    <p>
                                        These videos are formed to create a target audience and create a demand in the market, showcasing the USP of the product.  
                                        So before we jump into creating anything, we tend to thoroughly understand the feature, benefit and target audience.  
                                        Later on we determine the objective of the video, on how to increase sale and give a bang on effect in the audience's mind.  
                                        We create authentic video to gain the trust of the audience. 
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-5">
                                <div className="img-box">
                                    <img style={{ border: "3px solid yellow" }} src="images/services/1.jpg"alt="Documentary Films"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            Documentary Films
                                        </h2>
                                    </div>
                                    <p>
                                    We help to define the niche which can be based on various subjects ranging from nature, history, social issues and many more. 
                                    Creating documentary involves a lot of challenges, that we can overcome with the expertise of filmmaking, research skills and deep understanding of the subject.  
                                    With the help of the target professional we outline the clients goal, target audience, timeline and production and distribution. 
                                    We have a high quality equipment including cameras, lenses, lighting and sound recording gear and editing software. 

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-md-5">
                                <div className="img-box">
                                    <img style={{ border: "3px solid yellow" }} src="images/services/6.png" alt="TV Commercial"></img>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <h2>
                                            TV Commercial
                                        </h2>
                                    </div>
                                    <p>
                                        For creating the TV commercial, one needs to have the business acumen to understand the product and then develop a commercial that is apt with theme as well as carrying the amalgamation of the creative vision. 
                                        We as a team, we research our competitors and determine the market trends and focus on specific industries and demographic structure.  
                                        Our team is loaded with comprehensive business planning, outlining the company's mission and target market. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <br></br>

                        <div className="text-center">
                            <p>
                                So once you contact us, you should be rest assured that your collaborating with the company who have diverse knowledge and skill, in various pre-production and post production process of video making. 
                            </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            <Link to="/contact">
                                <button type="submit" className="btn btn-warning">
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>
            </section>

            {/* <!-- end who section --> */}
        </body >
        </>
    )
}

export default ServicesPage;