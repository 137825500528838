import { Link } from "react-router-dom";
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const Gallery = () => {
    return(
        <>
        <body className="sub_page">
                <div className="hero_area">
                    {/* <!-- header section strats --> */}
                    <header className="header_section">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                                <Link to='/' className="navbar-brand">
                                    <span>
                                        <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                    </span>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                        <ul className="navbar-nav  ">
                                        <li className="nav-item">
                                            <Link to='/' className="nav-link"> Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/about' className="nav-link"> About Us</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/services' className="nav-link"> Services </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                        </li>
                                        <li className="nav-item active-menu">
                                            <Link to='/gallery' className="nav-link"> Gallery </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to='/contact' className="nav-link">Contact us</Link>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- end header section --> */}
                </div>


                {/* <!-- gallery section --> */}

                <section className="work_section layout_padding">
                    <div className="container">
                        <div className="heading_container">
                            <h2>
                                Gallery
                            </h2>
                        </div>
                            <p>
                                Teaser release conference of our documentary film <span className="text-bold">THE BURNT SOIL</span>, at PRESS CLUB, Kolkata.
                            </p>
                            <p>
                            Here are all the wonderful individuals who made this event even more special:<br></br>
                                Dr. Arnab Gupta (Director, Dr. Saroj Gupta Cancer Hospital),
                                Prof. Ambikesh Mahapatra (Social Activist),
                                Dr. Shouvik Mukhopadhyay (History Professor, Calcutta University),
                                Mr. Raja Sen (National Award Winning Film Director),
                                Dr. Tarit Roychowdhury (Associate Professor, Department of Environmental Studies, Jadavpur University),
                                Prof. Avijit Das (Economics, CU),
                                Mr. Arunava Ganguly (National Award Winning Film Director),
                                Mr. Sudipta Dey (Film Director),
                                Mrs. Diti Mukherjee (Environmentalist) and 
                                Mr. Sujit Prajapati (West Bengal Earthen Potter Association)
                            </p>
                        <div className="work_container layout_padding2">
                            <div className="galleryimg">
                                <div className="column">
                                    <img src="images/press_conference/1.webp" alt="press release, an event held at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/2.webp" alt="press release, an event held at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/3.webp" alt="press release, an event held at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/4.webp" alt="documentary release event of big creative ideas entertainment at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/5.webp" alt="documentary release event of big creative ideas entertainment at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/6.webp" alt="documentary release event of big creative ideas entertainment at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/7.webp" alt="documentary release event of big creative ideas entertainment at press club" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/8.webp" alt="Press club event of big creative ideas entertainment" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/9.webp" alt="Press club event of big creative ideas entertainment" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/10.webp" alt="Press club event of big creative ideas entertainment" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/11.webp" alt="Press club event of big creative ideas entertainment" />
                                </div>
                                <div className="column">
                                    <img src="images/press_conference/12.webp" alt="Press club event of big creative ideas entertainment" />
                                </div>
                            </div>
                        </div>

                        <div className="heading_container mt-5">
                            <h2>
                                Teaser Film
                            </h2>
                        </div>
                        <div className="work_container layout_padding2">
                            <div className="box b-1">  
                                    <iframe className="responsive-iframe" width="560" height="315" src="https://www.youtube.com/embed/1bfbBX5a7zs?si=RUkwQPcLqtJqFJVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="box b-2 my-5">
                                   
                            </div>
                            <div className="box b-3 mx-4">

                            </div>
                            <div className="box b-4 my-3">

                            </div>
                        </div>
                    </div>
                </section>
            

                {/* <!-- end gallery section --> */}
            </body>
        </>
    )
};

export default Gallery;