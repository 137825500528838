import { Link } from "react-router-dom";
const bigLogo = "BCIE_logo-3-1-removebg-preview.png";

const PageNotFound = () => {
    return (
        <>
            <body className="sub_page">
                <div className="hero_area">
                    {/* <!-- header section strats --> */}
                    <header className="header_section">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                                <Link to='/' className="navbar-brand">
                                    <span>
                                        <img className="logo-img" src={bigLogo} alt="company logo"></img>
                                    </span>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                        <ul className="navbar-nav  ">
                                            <li className="nav-item">
                                                <Link to='/' className="nav-link"> Home </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/about' className="nav-link"> About Us</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/services' className="nav-link"> Services </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/portfolio' className="nav-link"> Portfolio </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/gallery' className="nav-link"> Gallery </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/contact' className="nav-link">Contact us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- end header section --> */}
                </div>
                <h4 className="text-center my-4">Error <span className="text-danger">404</span> - Page Not Found</h4>
                <div className="text-center my-4">
                    <Link to="/"><button type="button" className="btn btn-warning">Return Home</button></Link>
                </div>
            </body>
        </>
    )
}

export default PageNotFound;